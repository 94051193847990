//
//
//


$font-FontAwesome: 'Font Awesome 5 Pro';
$font-BodyText: 'Open Sans', $font-FontAwesome, arial, sans-serif;

$font-SizeSmaller: 0.875rem; // 14px;
$fontsize-Footer: 13px / 1rem * 100%;

$font-SizeH1: 2rem;     // 32px
$font-SizeH2: 1.5rem;   // 24px
$font-SizeH3: 1.25rem;  // 20px
$font-SizeH4: 1.125rem; // 18px
$font-SizeH5: 1rem;     // 16px
$font-SizeH6: 0.875rem; // 14px -- hope we never use this one

$lineheight-Default: 1.5;

$color-Black: #000000;
$color-White: #ffffff;
$color-BackgroundGray: #f1f1f1;

$color-BodyBackground: #ffffff;
$color-BodyText: #151515;

$color-CaasppGold: #fec321;
$color-CaasppDarkGreen: #206051;
$color-CaasppLightGreen: #9cbf6d;
$color-CaasppPaleGreen: #c8dc9e;
$color-CaasppBlue-Light: #3468a5; // gb provided, no official caaspp color (i think)
$color-CaasppBlue-Dark: #13498a; // gb provided

$color-ElpacGold: #e6a319;
$color-ElpacBlue: #123574;

$color-EtsMidnight: #003356;
$color-EtsNavy: #003082;
$color-EtsAzure: #3072be;
$color-EtsCerulean: #2aace2;

$color-AlertDangerIconBackground: #df0000 !default;

$color-EtsMediumGray: #999999;
$color-FooterBackground: #f1f1f1;
$color-FooterText:       #151515;

$distance-OneUnit: 1rem;
$distance-BetweenContainers: 0.8rem;
$distance-PageMaxWidth: 1200px;

$border-Radius: 10px;

$transition-Fast: all 0.1s ease-in;
$transition-Medium: all 0.3s ease-in;
$transition-Slow: all 0.5s ease-in;




// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// ets colors
/// Defined colors
/// @group colors
$colors: (
	transparent:         transparent,

	etsMidnight:       #003356,
	etsNavy:           #003082,
	etsAzure:          #3072be,
	etsCerulean:       #b5cc18,
	etsGold:           #fdb913,
	etsPear:           #ded14f,
	etsEmerald:        #69cc97,
	etsHunter:         #1b1c1d,

	black:             #000000,
		mostlyblack:     #151515,
		fairlyblack:     #505050,

	blue:              #0000ff,
    darkblue:        #003082,
    lightblue:       #3072BE,

	gray:              #bcbcbc,
    verylightgray:   #f2f2f2,
    lightgray:       #d7d7d7,
    mediumgray:      #999999,
    lightdarkgray:   #3d3d3d,
    darkgray:        #666666,
    verydarkgray:    #333333,

	red:               #ff0000,

	white:             #ffffff,
	   offwhite:       #d5e3f2,
);

/// ETS colors
/// @group ets|colors
$ets-colors: (

	midnight: #003356,
	navy:     #003082,
	azure:    #3072be,
	cerulean: #2aace2,
	gold:     #fdb913,
	pear:     #ded14f,
	emerald:  #69cc97,
	hunter:   #2f7e61,

	corpdarkblue:           map-get($colors, darkblue),
	corplightblue:          map-get($colors, lightblue),

	bodytext:               map-get($colors, mostlyblack),
	headings:               map-get($colors, darkblue),

	footertext:             map-get($colors, mostlyblack),
	footeranchor:           map-get($colors, mostlyblack),
	footeranchorhover:      map-get($colors, mostlyblack),
  footersocialbackground: map-get($colors, lightgray),

  linkdefault: #2d69ac,
  linkactive:  #003356,
  linkhover:   #003082,
  linkfocus:   #003082,
  linkvisited: #003082,

);


// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// define breakpoints
//

// new ets.org (aligned with zurb foundation)
// dhj added 2019-05-16
@include mq($until: medium) {
	body:after {
		content: "small";
		display: none;
	}
}

@include mq($from: medium, $until: large) {
	body:after {
		content: "medium";
		display: none;
	}
}

@include mq($from: large, $until: xlarge) {
	body:after {
		content: "large";
		display: none;
	}
}

@include mq($from: xlarge, $until: xxlarge) {
	body:after {
		content: "xlarge";
		display: none;
	}
}

@include mq($from: xxlarge) {
	body:after {
		content: "xxlarge";
		display: none;
	}
}

// define breakpoints
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
