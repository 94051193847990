//
//
//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// imports

@import 'mq'; // github.com/sass-mq/sass-mq
@import 'variables';

// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// breakpoint notes
//
// - Small: any screen.
// - Medium: any screen 640 pixels or larger.
// - Large: any screen 1024 pixels or larger.
//
// Small only
// @media screen and (max-width: 39.9375em) {}
//
// Up to Medium
// @media screen and (max-width: 63.9375em) {}
//
// Medium and up
// @media screen and (min-width: 40em) {}
//
// Medium only
// @media screen and (min-width: 40em) and (max-width: 63.9375em) {}
//
// Up to Large
// @media screen and (max-width: 74.9375em) {}
//
// Large and up
// @media screen and (min-width: 64em) {}
//
// Large only
// @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
//
//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// override the foundation styles for select elements
//

* {
  line-height: 1.5; // if you change this...
                    // js-manual-subnav, fix the top position
  box-sizing: border-box
}

html,
body {
  height: 100.1%;
}

body {
  position: relative;
  color: $color-BodyText;
  background: $color-BodyBackground;
  font-family: $font-BodyText;
  font-weight: 400;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin: 1.5rem 0 0.3125rem; // 24px 0 5px
  color: $color-CaasppBlue-Dark;
  font-family: $font-BodyText;
  font-weight: 600;

  b,
  strong,
  .text-bold {
    font-weight: 800;
  }

  &.section-heading {
    margin-top: 2.5rem; // 40px
  }
}

h1, .like-h1 {
  font-size: 2em; // 32px
  margin-bottom: 0.625em; // 20px
  letter-spacing: -1px;
}
h2, .like-h2 { font-size: $font-SizeH2; }
h3, .like-h3 { font-size: $font-SizeH3; }
h4, .like-h4 { font-size: $font-SizeH4; }
h5, .like-h5 { font-size: $font-SizeH5; }
h6, .like-h6 { font-size: $font-SizeH6; }

a {
  color: map-get($ets-colors , linkdefault);

  &:visited {
    color: map-get($ets-colors , linkvisited);
  }
  &:hover {
    color: map-get($ets-colors , linkhover);
  }
  &:focus {
    color: map-get($ets-colors , linkfocus);
  }
  &:active {
    color: map-get($ets-colors , linkactive);
  }
}

main {
  a {
    text-decoration: underline;
  }
}

.relative {
  position: relative;
}



// prevent a flash of content as these overly large navigation
// structures load and pick up js style/functionality
.manual-navigation li > ul { display: none; }
.grid-x>.large-2 { min-width: 250px; }


//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// at the top of each page there are some hidden links in place that
// allow a user to skip to sections of the template
// these links are hidden until they recieve focus (via tabbing)
//
.access-links {
	a {
		position: absolute;
		top: -40px;
		left: 0;
    z-index: 100;
		padding: 0.5rem 1rem;
		color: $color-White;
		background-color: $color-EtsMidnight;
		font-size: 1rem;
		text-decoration: none;
		border-bottom-right-radius: $border-Radius;
		transition: $transition-Fast;
	}

	a:focus {
    position: absolute;
    top: 0;
    left: 0;
    text-decoration: none;
    outline: 0;
  }
}; // end .access-link

//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
//

.grid-container.is-home,
.grid-container.is-contents,
.grid-container.is-header {
  padding-left: 0;
  padding-right: 0;
}

body[class*="large"] {

  .grid-container.is-header,
  .grid-container.is-contents {
    max-width: 1200px;
  }

  .grid-container.is-contents {
    .grid-x > .large-2.wrap-current-manual-nav {
      width: 250px;
    }
  }
}

//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -

.site-search {
  padding-top: 0.8rem;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .form-site-search {
    position: relative;
    opacity: 0;
    // transform: translateX(calc(100%));
    // transition: transform 0.2s linear, opacity 0.8s linear;
    transition: opacity 0.3s linear;

    &.js-show {
      opacity: 1;
      // transform: translateX(0);
      // transition: transform 0.2s linear, opacity 0s linear;
      transition: opacity 0.4s linear;
    }

    .fa-search {
      display: none;
    }
  }

  input[type="text"],
  button[type="submit"] {
    display: inline-block;
    margin: 0;
    padding: 0.55em;
    font-size: 1rem;
    line-height: 1.2;
    font-family: $font-BodyText;
    border: 1px solid $color-BodyText;
    vertical-align: middle;
  }

  input[type="text"] {
    position: relative;
    z-index: 0;
    font-size: 0.9rem;
    // if you have a submit buton...
    // border-radius: $border-Radius 0 0 $border-Radius;
    // width: calc(100% - 3rem - 64px);
    // border-right: 0;
    //
    // if no submit button
    border-radius: $border-Radius;
    width: calc(100% - 0.8rem);
  }

  button[type="submit"] {
    margin-right: 64px;
    margin-left: -0.5em;
    width: 3rem;
    color: $color-White;
    background-color: $color-EtsMidnight;
    border-left: 0;
    border-radius: 0 $border-Radius $border-Radius 0;
  }

  .trigger-site-search {
    position: absolute;
    top: 0.5rem;
    right: 0;
    z-index: 10;
    font-size: 1.2rem;
    border-right: 10px solid white;
    border-radius: 0;
    cursor: pointer;


    > span {
      display: block;
      width: 3rem;
      height: 3rem;
      padding: 0.6rem 0 0.6rem 0.1rem;
      background-color: $color-BackgroundGray;
      border-radius: 50%;
      transition: background-color 0.3s linear;
    }

    &[aria-expanded="true"] {
      > span {
        padding-top: 0.7rem;
        background-color: transparent;
      }
    }

    .fa-times {
      display: none;
      font-size: 120%;
    }

  }

}; // site-search

.in-page-nav {
  margin: 1rem 0;
  padding: 0 0 0.5rem 0;
  background-color: $color-BackgroundGray;
  border-radius: 10px;

  h2 {
    margin: 0 0 0.5rem;
    padding: 0.5rem 1rem;
    color: $color-BodyText;
    font-size: $font-SizeH4;
    border-bottom: 1px solid $color-BodyText;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: 0;
      padding: 0;

      > a {
        display: block;
        margin: 0;
        padding: 0.2rem calc(1rem - 4px);
        color: $color-BodyText;
        text-decoration: none;
        font-size: $font-SizeSmaller;
        border-left: 4px solid $color-BackgroundGray;

        &.current,
        &:hover,
        &:focus {
          color: $color-CaasppBlue-Dark;
          border-color: $color-CaasppBlue-Dark;
        }
      }
    }
  }
}

.page-contents {
  padding: 1rem;

  h1, h2, h3, h4, h5, h6 {
    color: $color-EtsNavy;
  }

}

.wrap-top-of-page {
  position: sticky;
  bottom: 1rem;

  float: right;
  margin-right: -3rem;

  a {
    color: $color-BodyText;
    font-size: 2rem;
  }
}

.is-home {
  .wrap-page-contents {
    h1 {
      text-align: center;
    }
  }
}

.is-footer {
  background-color: $color-BackgroundGray;
  .footer {
    padding: 0.8rem;
  }

}

.hero-image {
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  border-bottom: 5px solid $color-CaasppGold;

  img {
    display: block;
    margin: 0 auto;
  }

}

//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// small
@media screen and (max-width: 63.9375em) {

  .logos {
    img {
      margin-left: 0.5rem;
      max-height: 1.5rem;
    }
  }


}; // small

//
// medium
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

  .logos {
    img {
      margin: 0.5rem;
      max-height: 2.2rem;;
    }
  }

}; // medium

//
// up to medium
@media screen and (max-width: 63.9375em) {

  .header {
    // border-bottom: 1px solid black; // thin line under the header
  }

  .logos * {
    vertical-align: middle; // otherwise there is small vertical shift phone/tablet
  }

  .trigger-site-search {
    outline: none;
  }

  .manual-hamburger {
    width: 4rem;
    height: 4rem;
    font-size: 1.2rem;
    border-right: 1px solid black;
    border-radius: 0;
    cursor: pointer;

    .fa-bars,
    .fa-times {
      height: 24px;
    }

    .fa-times {
      display: none;
      font-size: 120%;
    }

  }

  .manual-navigation {

    border-bottom: 6px solid $color-CaasppGold;

    a {
      display: block;
      margin: 0;
      padding: 0.5rem 1rem 0.4rem;
      color: $color-BodyText;
      text-decoration: none;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    ul {
      li {
        li > a {
          border-bottom: 1px solid $color-BodyText;
          padding-left: 2rem;
        }
      }
    }

    ul.js-manual-subnav {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s linear;

      &.open {
        max-height: 200px;
        transition: all 0.3s linear;
      }

      li.description {
        display: none;
      }

    }

    // -- --

    &.js-manual-nav {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

      &.open {
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }
    }

    .manual-title a {
      position: relative;
      padding-left: 1em;
      background-color: $color-BackgroundGray;;
      border-bottom: 1px solid $color-BodyText;

      &::after {
        content: '\00d7';
				position: absolute;
				top: 0;
				right: 1rem;
				width: 2rem;
        height: 100%;
        font-size: 150%;
				line-height: 40px;
				text-align: center;
				transform: rotate(-45deg);
				transition: $transition-Medium;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(0);
        }
      }
    }
  } // manual-navigation

  .current-manual-nav {

    h2 {
      margin: 0;
      padding: 0.625rem 4.8rem;
      font-size: $font-SizeH4;
      font-weight: 400;
      border-bottom: 1px solid $color-BodyText;

      &::after {
        content: '\00d7';
        position: absolute;
        top: 0;
        right: 0.9rem;
        width: 2rem;
        height: 100%;
        font-size: 150%;
        line-height: 40px;
        text-align: center;
        transform: rotate(-45deg);
        transition: $transition-Medium;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(0);
        }
      }
    }

    a {
      display: block;
      margin: 0;
      padding: 0.5rem 1rem 0.4rem 4.8rem;
      color: $color-BodyText;
      background-color: $color-BackgroundGray;
      text-decoration: none;

      &.current,
      &:hover,
      &:focus {
        color: $color-White;
        background-color: $color-EtsMidnight;
      }
    }


    a.js-current-manual-nav-sub-trigger {
      position: relative;
      &::after {
        content: '\00d7';
        position: absolute;
        top: 0;
        right: 0.9rem;
        width: 2rem;
        height: 100%;
        font-size: 150%;
        line-height: 40px;
        text-align: center;
        transform: rotate(-45deg);
        transition: $transition-Fast;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(0);
        }
      }
    }

    > ul {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s linear;

      &.open {
        max-height: 2000px; // a guess
        transition: all 0.3s linear;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: block;
        margin: 0;
        padding: 0;

        > a {
          border-bottom: 1px solid $color-BodyText;
        }
      }
    }

    ul li > ul {
      font-size: 0.875rem;
      // border-top: 1px solid $color-EtsMediumGray; // not needed here, only for large+

      max-height: 0;
      overflow: hidden;
      transition: all 0.3s linear;

      &.open {
        max-height: 400px; // a guess
        transition: all 0.3s linear;
      }

      > li {
        > a {
          padding-left: 7rem;
          font-weight: 400;
          border-top: 0;

          &:hover,
          &:focus {
            color: $color-BodyText;
            background-color: rgba($color-CaasppBlue-Light, 0.2);

          }
        }
      }
    }

  }

  .in-page-nav {
    margin: 1rem;
  }

  .page-contents {
    position: relative;
    padding-right: 3rem;
  }

  .wrap-top-of-page {
    margin-right: 0.5rem;
  }

}; // up to medium


.bp-large {
  .in-page-nav {
    margin-top: 7rem;
    margin-right: 1rem;
    margin-left: 1rem;
    position: fixed;
  }
}

//
// large and up
@media screen and (min-width: 64em) {

  .site-search {
    padding-top: 1rem; // try to center this vertically

    .trigger-site-search {
      display: none;
    }
    .form-site-search {
      position: relative;
      opacity: 1;

      .fa-search {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 1;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
      }

      input {
        padding-left: 2.5rem;

        &::placeholder {
          color: $color-BodyText;
          opacity: 0.8;
        }
      }

    }
  } // .site-search

  header {
    border-bottom: 5px solid $color-CaasppGold;
  }

  .logos {
    padding: 0.6rem 0 0.6rem 2rem;
    img {
      margin-right: 1rem;
      max-height: 50px;
    }
  }

  .manual-navigation {
    position: relative;
    margin: 0;
    padding: 0;
    background-color: $color-BackgroundGray;

    > ul {
      display: flex;
      justify-content: center;

      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;
      border-bottom: 1px solid black;

      > li {
        display: block;
        margin: 0;
        padding: 0;

        .manual-title {
          display: block;
          margin: 0;
          padding: 0;

          > a {
            display: block;
            padding: 0.5rem 2rem;
            color: $color-BodyText;
            border-right: 1px solid black;

            &[aria-expanded="true"],
            &:hover,
            &:focus {
              background-color: $color-CaasppGold;
            }

          }
        }

        &:last-of-type {
          .manual-title {
            > a {
              border: 0;
            }
          }
        }
      }
    }

    .js-manual-subnav {
      position: absolute;
      top: calc(2.6rem - 2px); // the '6' is due to line height
      left: calc(50% - 300px);
      display: block;
      width: 600px;
      height: 12.5rem;
      text-align: left;

      border: 1px solid $color-BackgroundGray;
      border-top: 5px solid $color-CaasppGold;
      border-radius: 0 0 20px 20px;
      box-shadow: 0 10px 20px rgba($color-Black, 0.3);
      overflow: hidden;

      margin: 0;
      padding: 0;
      list-style-type: none;

      background-color: white;
      z-index: 1;

      li {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $color-EtsMediumGray;

        &:first-of-type,
        &:last-of-type {
          border-bottom: 0;
        }

        > a {
          display: block;
          padding: 0.5rem 2rem;
          color: $color-CaasppBlue-Dark;

          &.current,
          &:hover,
          &:focus {
            color: $color-White;
            background-color: $color-CaasppBlue-Dark;
          }

        }
      }

    }

    ul.js-manual-subnav {
      display: none;
      li {
        margin-left: 230px; // this value appears 3x. this is 1.
        display: block;

        > a {
          padding: 0.2rem 1rem;
        }

        &:nth-of-type(2) {
          padding-top: 0.78rem;
        }

      }

      li.description {
        position: absolute;
        top: 0;
        left: -230px; // this value appears 3x. this is 2.
        padding: 1rem;
        width: 230px; // this value appears 3x. this is 3.

        // display: flex;

        > img {
          display: block;
          margin: 0 auto 1rem;
        }

      }

    }

  }

  .wrap-current-manual-nav {
    background-color: $color-BackgroundGray;
  }

  .current-manual-nav {

    h2 {
      margin: 0;
      padding: 1rem;
      line-height: 1.2;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      > li {
        margin: 0;
        padding: 0;

        > a {
          display: block;
          padding: 0.5rem 1rem 0.5rem;
          color: $color-BodyText;
          line-height: 1.2;
          border-top: 1px solid $color-EtsMediumGray;

          &:hover { // },
          // &:focus {
            color: $color-White;
            background-color: $color-CaasppBlue-Light;
          }

          &.current {
            color: $color-White;
            background-color: $color-CaasppBlue-Dark;

            &:hover,
            &:focus {
              color: $color-White;
              background-color: $color-CaasppBlue-Dark;
            }
          }

        }
      }
    }

    a.js-current-manual-nav-sub-trigger {
      position: relative;
      &::after {
        content: "\203A";
        position: absolute;
        top: 2px;
        right: 0.9rem;
        width: 32px;
        height: 32px;
        font-size: 150%;
        text-align: center;
        transition: all 0.25s ease-in;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(90deg);
          transition: all 0.1s ease-in;
        }
      }
    }

    li.has-current-channel {
      a {
        &::after {
          transform: rotate(90deg);
        }
      }
    }

    > ul > li.has-current-channel {
      border-left: 5px solid $color-CaasppBlue-Dark;
      a {
        margin-left: -5px;
      }
    }

    ul li > ul {
      font-size: 0.875rem;

      max-height: 0;
      overflow: hidden;
      transition: all 0.3s linear;

      &.js-current-manual-nav-sub-channels.has-current-channel {
        max-height: 100%;
        overflow: visible;
      }


      &.open {
        max-height: 400px; // a guess
        transition: all 0.3s linear;
        overflow: visible;
      }

      > li {
        > a {
          padding-left: 3rem;
          font-weight: 400;
          border-top: 0;

          &:hover,
          &:focus {
            // color: $color-BodyText;
            // background-color: rgba($color-CaasppBlue-Light, 0.2);

          }
        }
      }
    }

  }

  .in-page-nav {
    margin-top: 7rem;
    margin-left: 1rem;
    position: fixed;
    max-width: 282px;
  }

  .hide-top .wrap-top-of-page {
    display: none;
  }

  .page-contents {
    margin-left: 2rem;
  }

  // -- --

}; // large

//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -

.wrap-footer-contents {
  margin: 0;
  padding: 1rem;
  font-size: 13px;
  background-color: $color-BackgroundGray;

  a {
    color: $color-BodyText;
    text-decoration: none;
    display: inline-block;


    &:hover,
    &:focus {
      color: $color-BodyText;
      text-decoration: underline;
    }
  }

  .wrap-cya,
  .wrap-legal {
    margin-bottom: 1rem;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 0;
        padding: 0;

        &:last-of-type {
          border: 0;
        }

        a {
          margin: 0;
          padding: 0 5px 0 8px;
          border-left: 1px solid $color-BodyText;
        }

        &:first-of-type {
          a {
            padding-left: 0;
            border: 0;
          }
        }
      }
    }
  }
}


.alert {
  display: flex;

  margin: 1rem 0;
  padding: 0;

  border: 2px solid $color-EtsMediumGray;

  .wrap-alert-icon {
    display: flex;
    align-items: center;
    padding: 1rem;
    text-align: center;
    background-color: $color-BackgroundGray;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  .wrap-alert-text {
    padding: 1rem;
    a {
      color: $color-CaasppBlue-Light;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $color-CaasppBlue-Dark;
        text-decoration: underline;
      }
    }
  }


  &.attention {
    border-color: $color-CaasppBlue-Dark;
    .wrap-alert-icon {
      color: $color-White;
      background-color: $color-CaasppBlue-Dark;
    }
  }

  &.success {
    border-color: $color-CaasppDarkGreen;
    .wrap-alert-icon {
      color: $color-White;
      background-color: $color-CaasppDarkGreen;
    }
  }

  &.warning {
    border-color: $color-AlertDangerIconBackground;
    .wrap-alert-icon {
      color: $color-White;
      background-color: $color-AlertDangerIconBackground;
    }
  }

}


@import 'callouts';


@media print {

  * {
    font-family: serif;
  }

  .site-search,
  nav,
  .wrap-current-manual-nav,
  .wrap-in-page-nav {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: sans-serif;
  }

  header {
    margin: 0 auto;
    padding: 1rem 1rem 0;
    max-width: 100%;

    a[href] {
      text-decoration:  none;
      display: inline-block;
      max-height: 1in;

      &::after{
        content: '';
      }
    }

    img {
      max-width: 1in;
      margin-right: 0.25in;
    }
  }

  .is-footer {

    .wrap-footer-contents {
      padding: 0;
    }

    .large-6 {
      width: auto;
    }

    .large-offset-3 {
      margin-left: 0;
    }
  }

}